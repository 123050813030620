import { jsxs as x, jsx as a } from "react/jsx-runtime";
import { useState as j, useEffect as b, useMemo as P, createContext as G, useContext as z, useCallback as w, useRef as k } from "react";
import { classnames as B } from "@vgno/utils";
import { getStoriesOverlay as H } from "@vgno/video";
import './assets/VideoCarousel.css';var V = {};
Object.freeze(V);
var A = (t) => {
  var e;
  return ((e = t.additional) == null ? void 0 : e.metadata) || V;
};
const M = (t) => {
  const { preview_portrait: e } = A(t);
  if (!e)
    return null;
  try {
    return JSON.parse(e)[0][0].url;
  } catch {
    return null;
  }
}, W = (t) => {
  const { preview_vivi_category: e, preview: r } = A(t);
  return M(t) || e || r;
}, D = (t) => `${t.images.main}?t[]=c296x444q50`;
var y = { container: "_container_l7yqi_1", video: "_video_l7yqi_10", title: "_title_l7yqi_18", highlight: "_highlight_l7yqi_31" };
const Z = ({ asset: t, url: e, linkRef: r, videoRef: n, onClick: s, children: i }) => /* @__PURE__ */ x("a", { ref: r, href: e, className: y.container, onClick: s, children: [
  /* @__PURE__ */ a(
    "video",
    {
      className: y.video,
      disableRemotePlayback: !0,
      loop: !0,
      muted: !0,
      playsInline: !0,
      src: W(t),
      preload: "none",
      poster: D(t),
      ref: n
    }
  ),
  /* @__PURE__ */ a("h3", { className: y.title, children: /* @__PURE__ */ a("span", { className: y.highlight, children: t.title }) }),
  i
] });
var U = { container: "_container_8nj24_1", title: "_title_8nj24_10" };
const Q = () => /* @__PURE__ */ a("div", { className: U.container, children: /* @__PURE__ */ a("div", { className: U.title }) }), Y = "Chevron Left", J = {
  flexShrink: 0,
  height: "var(--icon-size, var(--space-l))",
  width: "auto"
}, K = (t) => /* @__PURE__ */ a("svg", { width: "24px", height: "24px", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol", "aria-label": Y, style: J, ...t, children: /* @__PURE__ */ a("path", { d: "M13.2929 3.29289C13.6834 2.90237 14.3166 2.90237 14.7071 3.29289C15.0976 3.68342 15.0976 4.31658 14.7071 4.70711L7.41421 12L14.7071 19.2929C15.0976 19.6834 15.0976 20.3166 14.7071 20.7071C14.3166 21.0976 13.6834 21.0976 13.2929 20.7071L5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L13.2929 3.29289Z", fill: "var(--icon-color, currentColor)" }) }), X = K, tt = "Chevron Right", et = {
  flexShrink: 0,
  height: "var(--icon-size, var(--space-l))",
  width: "auto"
}, rt = (t) => /* @__PURE__ */ a("svg", { width: "24px", height: "24px", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol", "aria-label": tt, style: et, ...t, children: /* @__PURE__ */ a("path", { d: "M10.7071 3.29289C10.3166 2.90237 9.68342 2.90237 9.29289 3.29289C8.90237 3.68342 8.90237 4.31658 9.29289 4.70711L16.5858 12L9.29289 19.2929C8.90237 19.6834 8.90237 20.3166 9.29289 20.7071C9.68342 21.0976 10.3166 21.0976 10.7071 20.7071L18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L10.7071 3.29289Z", fill: "var(--icon-color, currentColor)" }) }), nt = rt;
var _ = { container: "_container_3pp8y_1", slider: "_slider_3pp8y_5", chevron: "_chevron_3pp8y_19" };
const st = ({ sliderRef: t, children: e }) => {
  const [r, n] = j("left"), s = (i) => {
    const o = i === "left" ? -1 : 1;
    t.current.scrollBy({
      left: o * t.current.clientWidth,
      behavior: "smooth"
    });
  };
  return /* @__PURE__ */ x("div", { className: _.container, children: [
    /* @__PURE__ */ a(
      "div",
      {
        className: _.slider,
        ref: t,
        onScroll: () => {
          const { offsetWidth: i, scrollLeft: o, scrollWidth: u } = t.current;
          o === 0 ? n("left") : o + i >= u ? n("right") : n("middle");
        },
        children: e
      }
    ),
    /* @__PURE__ */ a(
      "button",
      {
        className: _.chevron,
        "data-direction": "left",
        "aria-hidden": r === "left",
        onClick: () => s("left"),
        children: /* @__PURE__ */ a(X, {})
      }
    ),
    /* @__PURE__ */ a(
      "button",
      {
        className: _.chevron,
        "data-direction": "right",
        "aria-hidden": r === "right",
        onClick: () => s("right"),
        children: /* @__PURE__ */ a(nt, {})
      }
    )
  ] });
}, I = (t) => () => t;
var ot = I({
  playlistsApi: {
    url: "https://playlist.stream.schibsted.media"
  },
  providers: {
    vgtv: {
      app: {
        origin: "https://www.vg.no"
      }
    }
  }
}), it = I({
  playlistsApi: {
    url: "https://playlist-stage.stream.schibsted.media"
  },
  providers: {
    vgtv: {
      app: {
        origin: "https://stage.vg.no"
      }
    }
  }
});
const q = (t) => {
  switch (t) {
    case "staging":
      return it();
    default:
      return ot();
  }
}, at = ({
  asset: t,
  playlistId: e,
  env: r
}) => {
  var o;
  const { titleShortPath: n } = A(t), s = ((o = q(r).providers[t.provider]) == null ? void 0 : o.app.origin) || window.location.origin, i = new URL(`${s}/stories/${t.id}${n ? `/${n}` : ""}`);
  return e && i.searchParams.set("playlistId", e), i.toString();
};
var ct = class extends Error {
  constructor(e) {
    const r = e.status || e.status === 0 ? e.status : "", n = e.statusText || "", s = `${r} ${n}`.trim(), i = s ? `status code ${s}` : "an unknown error";
    super(`Request failed with ${i}`), this.name = "HttpError", this.response = e;
  }
}, L = 1208, $ = {
  Aftonbladet: "ab",
  Godare: "godare"
}, lt = (t) => t === $.Godare ? $.Aftonbladet : t, N = (t) => t.category.id === L || t.category.parentId === L, ut = (t, e) => t === $.Godare ? N(e) : t === $.Aftonbladet ? !N(e) : !0, dt = [
  "settings",
  "chapters",
  "cuePoints",
  "externalId",
  "externalCategoryId",
  "nextAsset",
  "sourceFiles",
  "subtitles",
  "metadata",
  "access",
  "tags",
  "stories"
].join("|"), ht = /^[A-Za-z0-9]{24}$/, ft = (t) => ht.test(t), mt = (t) => async ({
  playlistId: e,
  vendor: r
}, n) => {
  if (!ft(e))
    throw new ct({ status: 404, statusText: "invalid playlist id", json: () => Promise.reject() });
  const s = new URLSearchParams();
  s.set("additional", dt);
  const o = await (await t(`/${lt(r)}/${e}`, {
    ...n,
    searchParams: s
  })).json();
  return "resolved" in o ? {
    ...o,
    resolved: o.resolved.filter((u) => ut(r, u))
  } : o;
}, vt = (t) => ({
  getPlaylist: mt(t)
}), pt = class extends Error {
  constructor(t) {
    const e = t.status || t.status === 0 ? t.status : "", r = t.statusText || "", n = `${e} ${r}`.trim(), s = n ? `status code ${n}` : "an unknown error";
    super(`Request failed with ${s}`), this.name = "HttpError", this.response = t;
  }
}, gt = () => {
  try {
    if (typeof window < "u" && "fetch" in window)
      return fetch;
    if ("fetch" in globalThis)
      return fetch;
  } catch (t) {
    console.error('failed to resolve "fetch"', t);
  }
}, wt = (t) => {
  if (!t)
    return {
      signal: void 0,
      clear: () => {
      }
    };
  const e = new AbortController(), r = setTimeout(() => {
    e.abort();
  }, t), n = () => {
    clearTimeout(r);
  };
  return {
    signal: e.signal,
    clear: n
  };
}, yt = ({
  baseUrl: t,
  timeout: e = 2e3,
  fetch: r = gt()
}) => {
  const n = t.replace(/\/$/, "");
  return async (s, i = {}) => {
    const { searchParams: o, timeout: u = e, ...m } = i, c = wt(u);
    let l = `${n}${s}`;
    if (o) {
      const v = new URLSearchParams(o).toString();
      l += `?${v}`;
    }
    const h = await r(l, { ...m, signal: c.signal });
    if (c.clear(), !h.ok)
      throw new pt(h);
    return h;
  };
};
const _t = (t) => {
  const {
    playlistsApi: { url: e }
  } = q(t);
  return vt(yt({ baseUrl: e }));
};
class Ct {
  constructor(e) {
    this.result = { isFetching: !0, data: e.initialData }, this.queryFn = e.queryFn;
  }
  getCurrentResult() {
    return this.result;
  }
  fetch(e) {
    setTimeout(async () => {
      const r = await this.queryFn();
      this.result = { isFetching: !1, data: r }, e(this.result);
    });
  }
}
const bt = (t) => {
  const [e, r] = j(t.getCurrentResult());
  return b(() => {
    t.fetch(r);
  }, [t]), e;
};
let C;
const $t = () => C || (C = new Promise((t) => {
  typeof window.pulse == "function" && window.pulse((e) => {
    e && t(e);
  });
}), C), T = async (t) => {
  try {
    return (await $t()).track("trackerEvent", t);
  } catch (e) {
    console.error(e);
  }
}, Et = () => {
}, Pt = (t, e, r, n) => r ? (t.addEventListener(e, r, n), () => {
  t.removeEventListener(e, r);
}) : Et, kt = (t, e) => {
  const r = Object.keys(e).map((n) => {
    const s = n;
    return Pt(t, s, e[s]);
  });
  return () => {
    r.forEach((n) => n());
  };
}, xt = (t) => t.charAt(0).toUpperCase() + t.slice(1), O = G(void 0), At = ({ contentPublisher: t, contentType: e, contentId: r, children: n }) => {
  const s = P(() => t && e && r ? {
    trackEvent: (i) => T({
      ...i,
      object: Object.assign({}, i.object, {
        page: {
          "@type": xt(e),
          "@id": `sdrn:${t}:${e}:${r}`
        }
      })
    })
  } : {
    trackEvent: T
  }, [t, e, r]);
  return /* @__PURE__ */ a(O.Provider, { value: s, children: n });
}, St = {
  trackEvent: () => Promise.resolve()
}, Ut = () => z(O) ?? St, Lt = (t) => {
  const e = t.current.filter((r) => !!r).map((r) => kt(r, {
    mouseenter: () => {
      r.play();
    },
    mouseleave: () => {
      r.pause(), r.currentTime = 0;
    }
  }));
  return () => e.forEach((r) => r());
}, Nt = (t, e) => {
  const r = [];
  let n;
  const s = (o, u) => {
    r[u] = o.intersectionRatio, window.clearTimeout(n), n = window.setTimeout(() => {
      const m = r.map((c, l) => ({
        intersectionRatio: c,
        index: l
      })).reduce((c, l, h) => (h === r.length - 1 ? l.intersectionRatio >= c.intersectionRatio : l.intersectionRatio > c.intersectionRatio) ? l : c);
      t.current.forEach((c, l) => {
        c && (m.index === l ? c.play() : (c.currentTime = 0, c.pause()));
      });
    }, 300);
  }, i = new IntersectionObserver(
    (o) => {
      o.forEach((u) => {
        s(
          u,
          t.current.findIndex((m) => m === u.target)
        );
      });
    },
    { threshold: 1, root: e.current }
  );
  return t.current.forEach((o) => {
    o && i.observe(o);
  }), () => i.disconnect();
}, Tt = () => ({ enablePreview: w(
  (e, r) => "ontouchstart" in window && window.navigator.maxTouchPoints > 0 ? Nt(e, r) : Lt(e),
  []
) }), Rt = ({
  onView: t,
  threshold: e = 1,
  elementRef: r,
  once: n
}) => {
  b(() => {
    const s = r.current;
    if (!s)
      return;
    const i = new IntersectionObserver(
      ([o]) => {
        o.isIntersecting && (i.disconnect(), t());
      },
      { threshold: e }
    );
    return i.observe(s), () => i.disconnect();
  }, [t, e, n]);
}, jt = ({ asset: t, position: e }) => {
  const r = Ut(), n = w(() => {
    r.trackEvent({
      intent: "View",
      object: {
        "@type": "UIElement",
        "@id": `sdrn:${t.provider}:story:${t.id}:element:teaser`,
        position: e + 1
      }
    });
  }, [r, e, t]), s = w(() => {
    r.trackEvent({
      type: "Engagement",
      intent: "Open",
      object: {
        "@type": "UIElement",
        "@id": `sdrn:${t.provider}:story:${t.id}:element:teaser`,
        position: e + 1
      },
      target: {
        "@type": "Story",
        "@id": `sdrn:${t.provider}:story:${t.id}`
      }
    });
  }, [r, e, t]);
  return {
    trackView: n,
    trackClick: s
  };
}, Vt = ({ VideoComponent: t, asset: e, position: r, ...n }) => {
  const s = k(null), { trackView: i, trackClick: o } = jt({ asset: e, position: r });
  return Rt({
    onView: i,
    elementRef: s,
    once: !0,
    threshold: 0.7
  }), /* @__PURE__ */ a(t, { asset: e, linkRef: s, onClick: o, position: r, ...n });
}, It = (t) => t, qt = ({
  playlistId: t,
  playlistVendor: e,
  page: r,
  assets: n,
  getOverlay: s,
  getUrl: i = It,
  env: o = "production",
  components: { Slider: u, Video: m, Skeleton: c }
}) => {
  const l = k(null), h = k([]), { enablePreview: v } = Tt(), S = P(() => _t(o), [o]), F = P(
    () => new Ct({
      queryFn: async () => {
        if (n && (n == null ? void 0 : n.length) > 0)
          return { assets: n };
        if (!t)
          return { assets: [] };
        const { resolved: d } = await S.getPlaylist({ playlistId: t, vendor: e });
        return { assets: d };
      },
      initialData: { assets: [] }
    }),
    [S, t, e, n]
  ), {
    data: { assets: p }
  } = bt(F), E = w(
    (d, f) => {
      const g = at({ asset: d, playlistId: t, env: o });
      return i(g, { asset: d, position: f });
    },
    [t, o, i]
  );
  return b(() => {
    const d = p.map((f, g) => E(f, g));
    s == null || s().then((f) => f.preloadItems(d));
  }, [p, t, s, o, E]), b(() => {
    const d = v(h, l);
    return () => d();
  }, [p, v]), /* @__PURE__ */ a(At, { ...r, children: /* @__PURE__ */ a(u, { sliderRef: l, children: p.length > 0 ? p.map((d, f) => /* @__PURE__ */ a(
    Vt,
    {
      asset: d,
      url: E(d, f),
      position: f,
      videoRef: (g) => {
        h.current[f] = g;
      },
      VideoComponent: m
    },
    d.id
  )) : [...Array(8)].map((d, f) => /* @__PURE__ */ a(c, {}, f)) }) });
};
var R = { container: "_container_13rt8_1", header: "_header_13rt8_27" };
const Ot = ({ playlistHeader: t, trackingDataRenderer: e, ...r }) => {
  const n = ({ asset: s, position: i, ...o }) => /* @__PURE__ */ a(Z, { asset: s, position: i, ...o, children: e == null ? void 0 : e(s, i) });
  return /* @__PURE__ */ x("div", { className: R.container, children: [
    t && /* @__PURE__ */ a("h2", { className: R.header, children: t }),
    /* @__PURE__ */ a(
      qt,
      {
        ...r,
        components: {
          Slider: st,
          Video: n,
          Skeleton: Q
        }
      }
    )
  ] });
}, Ft = "_container_ebrub_1", Gt = {
  container: Ft
}, zt = () => new Promise((t) => {
  const e = H();
  e && t(e);
}), Zt = ({
  className: t,
  playlistId: e,
  title: r,
  videos: n,
  page: s,
  ...i
}) => {
  const o = w(
    (u, { asset: m }) => {
      const c = n == null ? void 0 : n.find((h) => h.id === m.id);
      if (!c)
        return u;
      const l = new URL(u);
      return new URL(c.url).searchParams.forEach((h, v) => {
        l.searchParams.set(v, h);
      }), l.toString();
    },
    [n]
  );
  return /* @__PURE__ */ a(
    "section",
    {
      className: B("font-inter", Gt.container, t),
      ...i,
      children: /* @__PURE__ */ a(
        Ot,
        {
          playlistId: e,
          playlistHeader: r,
          playlistVendor: "vgtv",
          page: s,
          assets: n,
          getUrl: o,
          getOverlay: zt
        }
      )
    }
  );
};
export {
  Zt as default
};
